import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { LoadingOverlayComponent } from '../_shared/components/loading-overlay/loading-overlay.component';
import { AppService } from '../_shared/services/app.service';
import { PermissionService } from '../_shared/services/permission.service';
import { ProjectService } from '../_shared/services/project.service';
import { QuoteService } from '../_shared/services/quote.service';
import { SelectedCustomerService } from '../_shared/services/selected-customer.service';
import { SnackBarService } from '../_shared/services/snack-bar.service';
import { ValidateValueStateMatcher } from '../_shared/validation/ValidateValueStateMatcher';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit, OnDestroy {
    @ViewChild('questionaire')
    public questionaireElement: ElementRef;

    @ViewChild('offerTitleInput')
    public offerTitleElement: ElementRef;

    @ViewChild('contentContainer')
    public wrapperDiv: ElementRef;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    private originalOfferTitle: string;

    public loaderTextKey = 'CONFIGURATION.LOADER.LOADING_WIZARD';
    public isOfferTitleDisabled = true;
    private dialogRef: MatDialogRef<LoadingOverlayComponent>;
    public offerTitle: string;
    public projectIdControl: FormControl<string>;
    public quickRef = '...';
    public latestRevision: string;
    public documentId: string;
    public quotationRequestId: string;
    public prefilled: boolean;

    public isQuestFormReady = false;
    public isQuestFormComplete = false;
    public errorOnEmptyMatcher = new ValidateValueStateMatcher((value) => value !== '');

    constructor(
        private activatedRoute: ActivatedRoute,
        private appService: AppService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        private quoteService: QuoteService,
        private dialog: MatDialog,
        public permissionService: PermissionService,
        private selectedCustomerService: SelectedCustomerService,
        private projectService: ProjectService
    ) {}

    public submit() {
        (<any>this.questionaireElement).submit();
    }

    public onQuestFormReady() {
        this.isQuestFormReady = true;
    }

    public onQuestFormComplete(isComplete: boolean) {
        this.isQuestFormComplete = isComplete;
        const selectedPartner = this.selectedCustomerService.customerForPartnerMode$.value;
        if (selectedPartner && !this.selectedCustomerService.selectedCustomer$.value) {
            // preset selected partner if partner mode was active
            this.selectedCustomerService.selectedCustomer$.next(selectedPartner);
        }
    }

    public onQuestSubmit() {
        this.projectIdControl.markAsTouched();
    }

    public ngOnInit() {
        this.projectIdControl = new FormControl<string>('', {
            validators: [Validators.required],
            asyncValidators: [this.validateProjectNumber.bind(this)],
            updateOn: 'blur',
        });
        this.projectIdControl.statusChanges
            .pipe(
                filter((status) => status === 'VALID' && !!this.projectIdControl.value),
                map(() => this.projectIdControl.value.padStart(10, '0')),
                switchMap((projectId) => {
                    this.onOfferTitleBlur();
                    return this.quoteService.updateQuoteProperty('projectIdSF', projectId);
                })
            )
            .subscribe();
        this.quoteService
            .getCurrentQuoteObservable()
            .pipe(filter((q) => !!q))
            .subscribe((quote) => {
                const projectNumber = quote.projectId;
                if (projectNumber && !this.projectIdControl.value) {
                    this.projectIdControl.setValue(projectNumber);
                    this.projectIdControl.markAsTouched();
                }
            });
        this.assignPropertiesFromActivatedRoute();
        this.quoteService.quoteErrors$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.showSnackBar('CONFIGURATION.SNACK_BAR.SAVE_QUOTE.ERROR', true);
        });
    }

    public onOfferTitleClick() {
        this.isOfferTitleDisabled = false;
    }

    public onOfferTitleBlur() {
        this.originalOfferTitle = this.originalOfferTitle || '';
        this.offerTitle = this.offerTitle || '';

        this.isOfferTitleDisabled = true;

        if (this.originalOfferTitle !== this.offerTitle.trim()) {
            this.updateQuoteTitle(this.offerTitle);
        }
    }

    private assignPropertiesFromActivatedRoute(): void {
        const { title, quickRef, documentId, latestRevision, prefilled, salesforceId, quotationRequestId } =
            this.activatedRoute.snapshot.queryParams;

        this.documentId = documentId;
        this.latestRevision = latestRevision;
        this.quickRef = quickRef;
        this.originalOfferTitle = title;
        this.offerTitle = title;
        this.prefilled = prefilled;
        if (salesforceId) {
            this.appService.salesforceId$.next(salesforceId);
        }
        if (quotationRequestId) {
            this.quotationRequestId = quotationRequestId;
        }
    }

    private validateProjectNumber(control: AbstractControl): Observable<ValidationErrors> {
        return this.projectService.getProject(control.value).pipe(
            map((project) => {
                const invalid = project.error || project.salesOrg !== this.appService.getSalesOrg();
                const primaryPartner = project.projectPartners?.find((p) => p.isPrimaryPartner === true);
                if (primaryPartner) {
                    this.selectedCustomerService.selectedCustomer$.next({ number: primaryPartner.sapId });
                }
                // If there is no title yet and the project number is valid, set the title equal to the project description.
                if (!this.offerTitle && !invalid) {
                    this.offerTitle = project.description1;
                }
                return invalid ? { invalid: true } : null;
            })
        );
    }

    private updateQuoteTitle(newTittle: string) {
        this.openDialog();

        this.quoteService.updateQuoteTitle(newTittle).subscribe(
            () => {
                this.closeDialog();
                this.originalOfferTitle = this.offerTitle;
                this.showSnackBar('CONFIGURATION.SNACK_BAR.UPDATE_OFFER_TITLE.SUCCESS', false);
            },
            () => {
                this.closeDialog();
                this.offerTitle = this.originalOfferTitle;
                this.showSnackBar('CONFIGURATION.SNACK_BAR.UPDATE_OFFER_TITLE.FAILURE', true);
            }
        );
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private showSnackBar(translationKey: string, isFailure: boolean): void {
        this.snackBarService.openSnackBar({
            message: this.translateService.instant(translationKey),
            isFailure,
        });
    }

    private openDialog(): void {
        if (!this.dialogRef) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.width = '250px';
            dialogConfig.scrollStrategy = new NoopScrollStrategy();
            this.dialogRef = this.dialog.open(LoadingOverlayComponent, dialogConfig);
        }
    }

    private closeDialog(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }
}
